<template>
  <ValidationObserver ref="botSettings" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        :label="`${input.appSettingDescription}`"
        :label-for="`${input.appSettingName}`"
      >
        <b-input-group>
          <b-form-input
            :id="`${input.appSettingName}`"
            v-model="inputData.appSettingValue"
          ></b-form-input>
          <b-input-group-append>
            <b-overlay
              :show="bot.botSettingId === input.id && bot.isUpdatingBotSettings"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                v-if="inputData.hasAdvanceSetting"
                v-b-toggle="`advanced-${inputData.id}`"
                variant="light"
              >
                {{ $t('fields.advance_setting') }}
              </b-button>
              <b-button type="submit" variant="primary">
                {{ $t('buttons.save') }}
              </b-button>
            </b-overlay>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-collapse :id="`advanced-${inputData.id}`">
        <b-card>
          <b-row
            v-for="(asForm, index) in inputData.appAdvanceSettings"
            :key="index"
          >
            <b-col>
              <b-form-group label="From">
                <b-form-timepicker
                  v-model="asForm.beginTimeString"
                  locale="en"
                  :hour12="false"
                >
                </b-form-timepicker>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group label="To">
                <b-form-timepicker
                  v-model="asForm.endTimeString"
                  locale="en"
                  :hour12="false"
                >
                </b-form-timepicker>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group>
                <label>
                  {{ asForm.appSettingValue1Description }}
                  <b-link v-b-modal="`description1-${index}`">
                    <i class="uil uil-edit" />
                  </b-link>
                  <b-modal
                    :id="`description1-${index}`"
                    hide-header
                    hide-footer
                  >
                    <b-form-group :label="asForm.appSettingValue1Description">
                      <b-form-input
                        v-model="asForm.appSettingValue1Description"
                      ></b-form-input>
                    </b-form-group>
                    <b-button
                      size="sm"
                      @click="$bvModal.hide(`description1-${index}`)"
                      >OK</b-button
                    >
                  </b-modal>
                </label>
                <b-form-input v-model="asForm.appSettingValue1"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="pl-0">
              <b-form-group>
                <label>
                  {{ asForm.appSettingValue2Description }}
                  <b-link v-b-modal="`description2-${index}`">
                    <i class="uil uil-edit" />
                  </b-link>
                  <b-modal
                    :id="`description2-${index}`"
                    hide-header
                    hide-footer
                  >
                    <b-form-group :label="asForm.appSettingValue2Description">
                      <b-form-input
                        v-model="asForm.appSettingValue2Description"
                      ></b-form-input>
                    </b-form-group>
                    <b-button
                      size="sm"
                      @click="$bvModal.hide(`description2-${index}`)"
                      >OK</b-button
                    >
                  </b-modal>
                </label>
                <b-form-input v-model="asForm.appSettingValue2"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="1" class="p-0">
              <b-form-group :label="$t('buttons.remove')">
                <b-button
                  variant="danger"
                  @click="onRemoveAdvanceSetting(index)"
                >
                  <i class="uil uil-trash"></i>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" @click="onAddNewAdvanceSetting">
                <i class="uil uil-plus"></i>
                {{ $t('buttons.add') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      inputData: null,
      advanceSettingTypes: ['SINGLE', 'MULTIPLE'],
    }
  },
  computed: {
    ...mapState(['bot']),
  },
  watch: {
    input(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['updateBotSettings']),
    setData() {
      if (this.input) {
        this.inputData = JSON.parse(JSON.stringify(this.input))
      }
    },
    onSubmit() {
      // transform begin time and end time to the correct format.
      for (const asForm of this.inputData.appAdvanceSettings) {
        asForm.beginTime = asForm.beginTimeString
        asForm.endTime = asForm.endTimeString
      }

      this.updateBotSettings({
        id: this.input.id,
        data: {
          ...this.inputData,
        },
      })
    },
    onAddNewAdvanceSetting() {
      this.inputData.appAdvanceSettings.push({
        appSettingId: this.inputData.id,
        appSettingType: 'MULTIPLE',
        appSettingValue1: '',
        appSettingValue2: '',
        appSettingValue1Description: 'min user',
        appSettingValue2Description: 'max user',
        beginTime: null,
        endTime: null,
        beginTimeString: '',
        endTimeString: '',
      })
    },
    onRemoveAdvanceSetting(index) {
      this.inputData.appAdvanceSettings.splice(index, 1)
    },
    onToggleHasAdvanceSetting(status) {
      if (status) {
        if (this.inputData.appAdvanceSettings.length === 0) {
          this.onAddNewAdvanceSetting()
        }
      } else {
        this.inputData.appAdvanceSettings = []
      }
    },
  },
}
</script>
